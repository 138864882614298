<template>
  <div>
    <div v-for="(item, i) in files" :key="i" class="hoverable">
      <div class="px-2 py-1">
        <div class="d-flex align-center">
          <div class="font-weight-bold">
            <span class="mr-2">{{ i + 1 }}.</span>
            <span>{{ item.name }}</span>
            <v-icon color="success" class="ml-2"
              v-if="(filevalues[item._id] || {}).processed === 1">mdi-check-circle</v-icon>
            <v-icon color="info" class="ml-2" v-else>mdi-timer-sand</v-icon>
          </div>
          <v-spacer></v-spacer>
          <div v-if="fileproerr[item._id]" class="ml-2">
            <span class="error--text">{{ fileproerr[item._id] || "Unknown Error" }}</span>
          </div>
          <span v-if="filevalues[item._id]" @click="$nova.downloadFile((filevalues[item._id] || {})._id)"
            class="mx-2 cursor-pointer blue--text text--darken-2 caption">
            <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
            {{ filevalues[item._id].uploadname }}
          </span>
          <div v-if="fileprostage[item._id] === 1 && filecount" class="ml-2">
            <v-chip small color="info" :value="true" label> Validating </v-chip>
          </div>
          <div v-else-if="(filevalues[item._id] || {}).processed === 0" class="ml-2">
            <v-chip small label color="warning" :value="true">
              Processing
            </v-chip>
          </div>
          <div v-else-if="fileprostage[item._id] === 2 && filecount" class="ml-2">
            <v-chip small label color="info" :value="true"> Uploading </v-chip>
          </div>
          <v-btn color="primary" small class="mr-2" @click="$nova.downloadFile(id, {}, '/v2/bot/lease/getleasetemplate')">Download Template</v-btn>
          <lb-file v-if="((filevalues[item._id] || {}).processed !== 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))) && editable" v-model="filesdataPros[item._id]" label=""
            class="py-0 my-0 ml-2" :drag="false" hidedetails :loading="[1, 2].indexOf(fileprostage[item._id]) > -1"
            :loadingpercent="(Number(fileuppercent[item._id]) || 0) + filecount * 0" :displayname="false"
            accept=".xlsx,.xls,.xlsm,.xlsb,.csv" @change="FileProcess(item._id, item.columns)"></lb-file>
        </div>
        <div>
          <div class="grey--text text--darken-1 mb-1">Required columns:</div>
          <span class="caccounting--text mr-3 mb-2" v-for="(item, i) in requiredColumDefult" :key="i">
            <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <!-- Button or any activator element -->
            <v-chip color="lightpurple" v-bind="attrs" v-on="on" label class="caccounting--text mr-3 mb-2">{{ item.field }}</v-chip>
          </template>
          <span>{{ item.description }}</span>
        </v-tooltip>
             
          </span>

          <!-- <div v-if="(fileconfig[item._id] || {}).columnmap">
            <v-chip color="lightpurple" label class="caccounting--text mr-3 mb-2" v-for="(item, i) in (fileconfig[item._id] || {}).columnmap || []" :key="i">{{
              item[1] || "Unknown" }}</v-chip>
          </div>

          <div v-else>
            <v-chip color="lightpurple" label class="caccounting--text mr-3 mb-2" v-for="(item, i) in files[i].columns" :key="i">{{ item.name }}</v-chip>
          </div> -->
        </div>
      </div>
      <v-divider class=""></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
    filevalues: {
      type: Object,
      required: true,
    },
    fileproerr: {
      type: Object,
      required: true,
    },
    fileprostage: {
      type: Object,
      required: true,
    },
    fileuppercent: {
      type: Object,
      required: true,
    },
    storepath: {
      type: String,
      required: true,
    },
    // filecount: {
    //   type: Number,
    //   required: true,
    // },
    filesdataPros: {
      type: Object,
      required: true,
    },
    fileconfig: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    right:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      filecount: 0,
      fileuploadpercent: {},
      file:{},
      requiredColum:[{'Lessor':['Lessor','The name / Codename of the lessor. Alphanumerical, special charecters possible']},{'Lease_id':['Lease_id','ID of the lease. Alphanumerical, special charecters possible']}],
      requiredColumDefult:[
  { field: "Lessor", type: "string", description: "The name / Codename of the lessor. Alphanumerical, special charecters possible"},
  { field: "Lease_id", type: "string", description: "ID of the lease. Alphanumerical, special charecters possible" },
  { field: "Commencement_date", type: "date", description: "DD-MMM-YY" },
  { field: "End_date", type: "date", description: "DD-MMM-YY" },
  { field: "Installment", type: "number", description: "Initial Monetary Value of installment" },
  { field: "InterestRate", type: "number", description: "Percentage interest rate - 10% -> 10; 12.5% - 12.5" },
  { field: "InstallmentFrequency", type: "string", description: "Monthly, Quarterly, semiannual,annual" },
  { field: "IncrementValue", type: "number", description: "Optional, Fixed monetary increment value" },
  { field: "IncrementRate", type: "number", description: "Rate of increment applied to the installment" },
  { field: "IncrementType", type: "string", description: "Normal, Onetime, recurring - Where recurring increment has an fixed monetary value increment on increment component" },
  { field: "IncrementOnIncrement", type: "boolean", description: "Monetary value of the security deposit" },
  { field: "SecurityDeposit", type: "number", description: "Monetary value of the residual value" },
  { field: "ResidualValue", type: "number", description: "Monetary value of the initial expense" },
  { field: "InitialExpense", type: "number", description: "frequency of increments - In terms of number of installments before each increment" },
  { field: "IncrementFrequency", type: "string", description: "Beginning/ End of the instalment frequency" },
  { field: "InstallmentTime", type: "string", description: "DD-MMM-YY , Date from which the schedule and summaries for a lease should start" },
  { field: "OPBalDate", type: "string", description: "DD-MMM-YY , Date from which the schedule and summaries for a lease should be displayed" },
],
    };
  },
  methods: {
    FileProcess(id, columns) {
      if (this.filesdataPros[id] && (this.fileprostage[id] === 0 || this.fileprostage[id] === undefined)) {
        let success = false;
        this.fileprostage[id] = 1;
        this.filecount++;
        this.fileproerr[id] = "";
        this.file = this.filesdataPros[id] || {};
        let config = this.fileconfig[id] || {};
        let columArr = [];
        for (const i of columns) {
          columArr.push([i.name, i.name]);
        }
        // config.columncount = ((config?.columnmap?.length || 0) + (config?.ignoredcolumns?.length || 0)) || 0;
        this.$emit('startloading');
        // console.log("processing file");
        this.$nova
          .processConserveFile(this.file, config, columArr)
          .then(() => {
            this.fileprostage[id] = 2;
            this.filecount++;
            return this.$nova.uploadFile(this.axios, this.file, {
              progressfunction: (e) => {
                let p = (e.loaded * 100) / e.total;
                this.fileuploadpercent[id] = p.toFixed(0);
                this.filecount++;
                // console.log(this.fileuploadpercent[id]);
              },
            });
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.fileUpDetil = dt.data.data[0];

              return this.axios.post(
                this.storepath,
                {
                  data: { upload: this.fileUpDetil._id, file: id },
                }
              )
            } else throw new Error("Unable to upload file");
          })
          .then((dt) => {
            console.log(dt)
            if (dt.data.status === "success") {
               success = true;
              this.fileUploadedDetilas = dt.data.data;
              this.$emit("storedData", {"stored":this.fileUploadedDetilas,"uploaded":this.file})
            } else throw new Error(dt.data.message || "Error storing file");
          })
          .catch((err) => {
            this.fileproerr[id] = err.message || err || "Unknown error!";
            console.log(err);
          })
          .finally(() => {
            this.filesdataPros[id] = null;
            this.fileprostage[id] = 0;
            this.filecount++;
            this.file = {}
            this.loading  = (success) ? this.$emit('startloading') : this.$emit('stoploading');
            // this.$emit('stoploading');
          });
      }
    },
  },
};
</script>
